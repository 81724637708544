import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'
import { withRouter } from "react-router-dom";
import Loader from "./Loader";

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */

 const urlPrefix = "http://localhost:3000";

const HomepageHeading = ({ mobile }) => (
  <Container 
    style={{
        backgroundColor:"#13477b",
        padding:'30px'
    }} 
    text>
    <Header
      as='h1'
      className="header1"
      content='Maintain profibility in your e-commerce business'
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        display:"inline-block",
        marginTop: mobile ? '1em' : '1em',
        border:"0px solid white",
        padding:"3px",
        paddingBottom:"6px",
        paddingLeft:"10px",
        paddingRight:"10px"
      }}
    />
    
    <Header
      as='h2'
      content='Have you wanted Amazon seller central to work better?'
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
    <Button size='huge' className="getStartedButton" style={{marginTop:"60px", color:"white"}} onClick={()=>{
          window.location = urlPrefix + "/signup";
    }}>
      Get Started
      <Icon name='right arrow' />
    </Button>
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  constructor(props) {
    super(props);
  }

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: false })

  render() {

    const { children } = this.props
    const { props } = this.props
    const { fixed } = this.state
    
    return (
      <Responsive >
        
        {/* minWidth={Responsive.onlyTablet.minWidth} > */}
        {/* <video className="bg-video" autoplay="true" loop="true">
          <source src="/videos/bg_main_2.mov" type="video/quicktime"/>
        </video> */}

        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign='center'
            style={{                 
                padding: '0em 0em',
                backgroundColor:"#13477b"
             }}
            vertical
          >
          
            <HomepageHeading />
            <svg className="fill-svg" x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon></svg>
          </Segment>


        </Visibility>
        {children}
      </Responsive>
    )  
  }
}


DesktopContainer.propTypes = {
  children: PropTypes.node,
}


const ResponsiveContainer = ({ children, props }) => (
  <div>    
    <DesktopContainer props={props}>{children}</DesktopContainer>
  </div>
)


ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const fixed = false;

const HomepageLayout = (props) => (
  <>
          <Loader fadeOut={false}/>
  {/* <img src="/images/icons/icon.png" style={{display:"inline-block", width:"34px", height:"34px", marginLeft:"10px", marginTop:"8px"}}/> */}
  </>
)
const HomepageLayoutLocal = (props) => (
  
  <>

        <Menu
              className="topBarMenu"
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                {/* <Menu.Item as='a' active>
                  Home
                </Menu.Item>
                <Menu.Item as='a'>Work</Menu.Item>
                <Menu.Item as='a'>Company</Menu.Item>
                <Menu.Item as='a'>Careers</Menu.Item>
                 */}

              <img src="/images/icons/icon.png" style={{display:"inline-block", width:"34px", height:"34px", marginLeft:"10px", marginTop:"8px"}}/>
      
            {/* <Typography variant="p" color="inherit"  style={{veriticalAlign:"middle", fontSize:"24px", border:"0px solid white", flexGrow:0, textAlign:"left"}}noWrap> */}
              &nbsp;
              &nbsp;<span style={{display:"inline-block", fontWeight:"bold", fontSize:"1.3em", paddingTop:"18px", verticalAlign:"middle", color:"white"}}>Inventory Shark</span>

            {/* </Typography> */}

                <Menu.Item position='right'>
                  <Button className="homeButton" as='a' onClick={()=>{                      
                      window.location = urlPrefix + "/signin";
                  }}>
                    Sign in
                  </Button>
                  <Button className="homeButtonSignup" as='a' primary={fixed} style={{ marginLeft: '0.5em' }} onClick={()=>{
                      // props.history.push('/signup')
                      window.location = urlPrefix + "/signup";
                    }} >
                    Sign Up
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>


  <ResponsiveContainer props={props}>
  
  
    <Segment style={{ padding: '8em 8em', backgroundColor:"#013153", color:"white"}} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row style={{paddingTop:"100px", paddingBottom:"200px"}}>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '1em', color:"white" }}>
              Maximize Profability
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight:"300"}}>
              Set up custom alerts to get notified when important events happen for your business.
            </p>
          </Grid.Column>

          <Grid.Column floated='right' width={6}>
            {/* <Image bordered rounded size='large' src='/images/wireframe/white-image.png' /> */}

            {/* <Image rounded size='medium' src='/images/icons/icons8-growing-money-96.png' />  */}

          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{paddingTop:"100px", paddingBottom:"200px"}}>
          
          <Grid.Column width={8}>
      
            
            {/* <Image rounded size='medium' src='/images/icons/profit-finder.png' />  */}

          </Grid.Column>

          <Grid.Column floated='right' width={6}>

            
          <Header as='h3' style={{ fontSize: '2em', color:"white", paddingTop:"40px" }}>
            Stay on Top
            </Header>
            <p style={{ fontSize: '1.33em', fontWeight:"300" }}>
              With alerts you'll have a faster response time to adjust to an event.
            </p>

          </Grid.Column>
        </Grid.Row>




        <Grid.Row>
          <Grid.Column textAlign='center'>
          <Button size='huge' className="getStartedButton" style={{marginTop:"60px", color:"white"}}>
              
              Try It Now
            
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <svg className="fill-svg" x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polygon className="fill-inverse" points="2560 0 2560 100 0 100"></polygon></svg>

    </Segment>

    <Segment style={{ padding: '0em' }} vertical>

      <Grid celled='internally' columns='equal' stackable>
        <Grid.Row textAlign='center' style={{backgroundColor:"#2693ff", color:"white", fontWeight:"300",paddingTop:"100px", paddingBottom:"100px"}}>
          
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

          {/* <Image rounded size='small' style={{marginLeft:"auto", marginRight:"auto"}} src='/images/icons/icons8-inspection-96.png' />  */}

            <Header as='h3' style={{ fontSize: '2em', color:"white" }}>
              Inventory Management
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Keep up to date status of your inventory.              
              </p>
          </Grid.Column>
          
          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            
          {/* <Image rounded size='small' style={{marginLeft:"auto", marginRight:"auto"}} src='/images/icons/icons8-line-chart-96.png' />  */}

            <Header as='h3' style={{ fontSize: '2em', color:"white" }}>
              Auto Pricing Tool
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Use our auto pricing tools to target a price.              
            </p>
          </Grid.Column>

          <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
          {/* <Image rounded size='small' style={{marginLeft:"auto", marginRight:"auto"}} src='/images/icons/icons8-accounting-96.png' />  */}
            <Header as='h3' style={{ fontSize: '2em', color:"white" }}>
              Sourcing Management
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Manage supplier lists with bulk uploads
            </p>
          </Grid.Column>


        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '3em 0em',backgroundColor:"white", textAlign:"center" }} vertical>
      <Container text>
        <Header as='h3' style={{ fontSize: '2em', textAlign:"left" }}>
            Your Sales Broken Down Daily
        </Header>
        <p style={{ fontSize: '1.33em', textAlign:"left" }}>
            View your sales and understand your profits on a daily basis. Customize your dashboard to show a snapshot of exactly what you need with a detailed breakdown of all of your costs and sales.
        </p>
        <Button size='huge' className="getStartedButton" style={{marginTop:"60px", color:"white"}} onClick={()=>{
          window.location = urlPrefix + "/signup";
        }}>Get Started</Button>
        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase', paddingTop:"100px" }}
        >
        <a href='#'> </a>
        </Divider>
        <Header as='h3' style={{ fontSize: '2em' }}>
        Available on these marketplaces
        </Header>
  
          <img src="images/icons/amazon-tool.png" style={{paddingTop:"10px"}}/>

      </Container>
    </Segment>
    
    <Segment className="footerHome" inverted vertical style={{ padding: '5em 0em'}}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='About' />
              <List link inverted>
                <List.Item as='a'>Sitemap</List.Item>
                <List.Item as='a'>Contact Us</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Services' />
              <List link inverted>
                <List.Item as='a'>FAQ</List.Item>
                <List.Item as='a'>How To Access</List.Item>
                <List.Item as='a'>Privacy Policy</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4' inverted>
                Inventory Shark 
              </Header>
              <p>
                Yay Fun, Inc.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
 
  </ResponsiveContainer>
  </>
)


export default withRouter(HomepageLayout)