import React, { useState } from 'react';
import styled from 'styled-components'

const Styles = styled.div`

`
function Loader(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    let fadeOut = props.fadeOut;

return (
    <Styles>
            <div className={fadeOut ? "fadeOut" : "fadeIn"} style={{pointerEvents:"none", position:"absolute", zIndex:"300", height:"100vh", display:"table", width:"100%", backgroundColor:"white"}}>
                <div style={{margin:"0", height:"50px", width:"50px", display:"table-cell", textAlign:"center", verticalAlign:"middle"}}>
                    <div style={{marginBottom:"40vh"}}>
                        <img style={{width:"100px", height:"100px"}} src="/images/icons/icon.png"></img>
                    </div>
                </div>
            </div>
    </Styles>)

}

export default Loader
