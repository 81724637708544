import React, { Component } from 'react';

import styled from 'styled-components'
import {withRouter, Route, Switch } from 'react-router-dom';

//Page components
import HomePage from "./HomePageLayout";
import Privacy from './Privacy';
import Terms from './Terms';

const Styles = styled.div`

`

class Main extends Component {
  

render() {
    return (
        <Styles>
                       
            <Switch>

            <Route exact path="/" component={HomePage} />

            {/* <Route exact path="/signup" component={Signup} />      */}
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms" component={Terms} />

            </Switch>
    
        </Styles>
    )
}


}

export default withRouter(Main);