import React from 'react';
import styled from 'styled-components'

const Styles = styled.div`
    position:sticky; 
    top:0;
`

function Header(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
return (
    <Styles>
        <div style={{width:"100%", height:"80px", paddingTop:"15px", backgroundColor:"#2693ff"}}>           
            <div style={{width:"75%", marginLeft:"auto", marginRight:"auto"}}>
                <img style={{paddingLeft:"0px", width:"40px"}} src="images/icons/icon.png"/>
                <span style={{textShadow:"0px 1px #515151",fontSize:"25px", paddingLeft:"20px", verticalAlign:"super", color:"white"}}>Inventory Shark </span>
                <span style={{float:"right", verticalAlign:"bottom", color:"white", fontSize:"12px"}}>Last Updated <br/>August 2020</span>
            </div>             
        </div>
    </Styles>
)


}

export default Header
