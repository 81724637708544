import React from 'react';
import logo from './logo.svg';
import './App.css';
import Loader from "./components/Loader";
import Main from './components/Main';

import 'semantic-ui-css/semantic.min.css'

function App() {
  return (
    <>
    <Main/>
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <div>
    //     </div>        
    //   </header>
    // </div>
  );
}


export default App;
